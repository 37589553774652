import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Pair } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { PartySizeLocales } from '../PartySize.locales'
import type { PartySizeProps } from './PartySizeProps'

export function CollapsedPartySize({ field }: PartySizeProps) {
  const { formatMessage } = useLocales()
  const partySize = useWatch(field)
  return (
    <Text fontSize="m">
      <Pair left={formatMessage(PartySizeLocales.partySizeLabel)} right={`${partySize.min ?? ''} - ${partySize.max ?? ''}`} />
    </Text>
  )
}
