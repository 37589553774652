import { useMemo } from 'react'
import type { AccessRuleBundledUpgradeQuantityType } from '@sevenrooms/core/domain'
import { type Field, useFieldArray, useWatch, type UseFieldArrayAppend } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import {
  IconButton,
  Button,
  FormNumberInput,
  MultiSelectTree,
  RadioGroup,
  type RadioChoice,
  type RadioGroupProps,
} from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useAccessRuleContext } from '../../shared'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../PaymentPolicy.testIds'
import { type PaymentPolicyForm, getMultiSelectOptions } from '../PaymentPolicy.zod'
import type { PaymentPolicyProps } from './PaymentPolicyProps'

export function BundledUpgrades({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()
  const { fields, append, remove } = useFieldArray(field, 'bundledUpgrades')
  return (
    <VStack spacing="s">
      <Text fontWeight="bold">{formatMessage(PaymentPolicyLocales.bundledUpgrades)}</Text>
      <Text color="secondaryFont">{formatMessage(PaymentPolicyLocales.bundledUpgradesDescription)}</Text>
      <VStack spacing="m">
        {fields.map((upgrades, idx) => (
          <UpgradesGroup
            data-test={`${PaymentPolicyTestId.upgradesGroup}-${idx}`}
            field={field.prop(`bundledUpgrades.${idx}`)}
            key={upgrades.id}
            remove={() => remove(idx)}
          />
        ))}
      </VStack>
      <AddAnUpgrade field={field} append={append} />
    </VStack>
  )
}

function AddAnUpgrade({ field, append }: PaymentPolicyProps & { append: UseFieldArrayAppend<PaymentPolicyForm, 'bundledUpgrades'> }) {
  const { formatMessage } = useLocales()
  const upgrades = useWatch(field.prop('bundledUpgrades'))
  const disabled = upgrades.some(up => up.upgrades.length === 0)
  return (
    <Box mt="sm">
      <Button
        data-test={PaymentPolicyTestId.addAnUpgrade}
        variant="tertiary"
        icon="VMSWeb-add"
        noPadding
        disabled={disabled}
        onClick={() =>
          append({
            quantityType: 'PARTY_SIZE',
            quantity: null,
            upgrades: [],
          })
        }
      >
        {formatMessage(PaymentPolicyLocales.addAnUpgrade)}
      </Button>
    </Box>
  )
}

interface UpgradeProps {
  field: Field<PaymentPolicyForm['bundledUpgrades'][number]>
  remove: () => void
  'data-test': string
}
function UpgradesGroup({ field, remove, 'data-test': testId }: UpgradeProps) {
  const { formatMessage } = useLocales()
  const { venueCurrencyCode } = useAppContext()
  const { upsells } = useAccessRuleContext()
  const choices = useUpgradesChoices(field, testId)
  const options = useMemo(() => getMultiSelectOptions(upsells, venueCurrencyCode), [upsells, venueCurrencyCode])
  return (
    <VStack data-test={testId} spacing="s">
      <HStack spacing="xs">
        <Box width="580px">
          <MultiSelectTree
            data-test={`${testId}-upgrades-multi-select`}
            id={`${testId}-upgrades-multi-select`}
            placeholder={formatMessage(PaymentPolicyLocales.searchUpgrades)}
            treeData={options}
            field={field.prop('upgrades')}
            hideErrorMessage
          />
        </Box>
        <IconButton
          data-test={`${testId}-delete-button`}
          borderType="none-round"
          icon="VMSWeb-trash"
          iconColor="primaryIcons"
          onClick={remove}
        />
      </HStack>
      <Quantity data-test={testId} choices={choices} field={field.prop('quantityType')} upgrades={field.prop('upgrades')} />
    </VStack>
  )
}

type QuantityProps = RadioGroupProps<PaymentPolicyForm['bundledUpgrades'][number]['quantityType']> & {
  upgrades: Field<PaymentPolicyForm['bundledUpgrades'][number]['upgrades']>
  'data-test': string
}
function Quantity({ upgrades: field, 'data-test': testId, ...props }: QuantityProps) {
  const { formatMessage } = useLocales()
  const upgrades = useWatch(field)
  return upgrades != null ? (
    <Box pl="lm">
      <VStack spacing="s">
        <Text>{formatMessage(PaymentPolicyLocales.quantity)}</Text>
        <RadioGroup data-test={`${testId}-quantity-choice`} {...props} disabled={upgrades.length === 0} />
      </VStack>
    </Box>
  ) : null
}

function useUpgradesChoices(field: UpgradeProps['field'], testId: string) {
  const { formatMessage } = useLocales()
  const choices: RadioChoice<AccessRuleBundledUpgradeQuantityType>[] = useMemo(
    () => [
      {
        value: 'PARTY_SIZE',
        label: formatMessage(PaymentPolicyLocales.quantityChoiceParty),
      },
      {
        value: 'SPECIFIC_NUMBER',
        label: formatMessage(PaymentPolicyLocales.quantityChoiceFixed),
        description: <PerReservation data-test={`${testId}-per-reservation`} field={field} />,
      },
    ],
    [formatMessage, field, testId]
  )
  return choices
}

function PerReservation({ field, 'data-test': testId }: Omit<UpgradeProps, 'remove'>) {
  const quantityType = useWatch(field.prop('quantityType'))
  const upgrades = useWatch(field.prop('upgrades'))
  return upgrades != null && quantityType === 'SPECIFIC_NUMBER' ? (
    <Box width="114px">
      <FormNumberInput data-test={testId} field={field.prop('quantity')} disabled={upgrades.length === 0} />
    </Box>
  ) : null
}
