import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { GuestDurationPickerFields, CollapsedGuestDurationPicker, DefaultGuestDurationPicker } from './components'
import { GuestDurationPickerLocales } from './GuestDurationPicker.locales'
import { GuestDurationPickerTestId } from './GuestDurationPicker.testIds'
import type { GuestDurationPickerForm } from './GuestDurationPicker.zod'

export interface GuestDurationPickerProps {
  field: Field<GuestDurationPickerForm>
}

export function GuestDurationPicker({ field }: GuestDurationPickerProps) {
  const { formatMessage } = useLocales()

  return (
    <AccessRuleSlideoutSection
      title={formatMessage(GuestDurationPickerLocales.title)}
      description={formatMessage(GuestDurationPickerLocales.description)}
      subCaption={formatMessage(GuestDurationPickerLocales.subCaption)}
      contentWhenCollapsed={<CollapsedGuestDurationPicker field={field} />}
      defaultSettings={<DefaultGuestDurationPicker />}
      sectionControllerName="guestDurationPicker"
      field={field}
      data-test={GuestDurationPickerTestId.section}
    >
      <GuestDurationPickerFields field={field} />
    </AccessRuleSlideoutSection>
  )
}
