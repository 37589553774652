import { useMemo } from 'react'
import type { ChargePerType } from '@sevenrooms/core/domain'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { Flex, Grid, Banner, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { notNullish } from '@sevenrooms/core/utils'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../PaymentPolicy.testIds'
import { useChargeChoices } from './radio/RadioComponents'
import type { PaymentPolicyProps } from './PaymentPolicyProps'
import type { PaymentPolicyForm } from '../PaymentPolicy.zod'

export function Totals({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()
  const { venueCurrencyCode } = useAppContext()
  const totals = useGetTotals(field)
  const currencyFormatter = useMemo(
    () => Intl.NumberFormat(TimeLocale.getLocale(), { style: 'currency', currency: venueCurrencyCode }),
    [venueCurrencyCode]
  )

  return totals.length > 0 ? (
    <Banner
      data-test={PaymentPolicyTestId.totalAdvancedCharges}
      title={
        <Text>
          {formatMessage(PaymentPolicyLocales.total, {
            strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
          })}
        </Text>
      }
      type="info"
      description={
        <VStack spacing="xs">
          {totals.map(([chargePerType, total]) => (
            <Grid
              key={`${chargePerType}-${total}`}
              gridTemplateColumns="repeat(12, 1fr)"
              gap="lm"
              gridAutoRows="min-content"
              alignItems="end"
            >
              <Flex maxWidth="100%" gridColumn="auto / span 2" justifyContent="flex-end">
                <Text>{currencyFormatter.format(total)}</Text>
              </Flex>

              <Flex maxWidth="100%" gridColumn="auto / span 10" justifyContent="flex-start">
                <Text>{chargePerType}</Text>
              </Flex>
            </Grid>
          ))}
        </VStack>
      }
    />
  ) : null
}

export function getTotals(
  upgrades: PaymentPolicyForm['bundledUpgrades'],
  bookingCharge: PaymentPolicyForm['bookingCharge'],
  paymentRule: PaymentPolicyForm['paymentRule']
) {
  const groups = new Map<ChargePerType, number>()
  for (const upgrade of upgrades ?? []) {
    let sum = upgrade.upgrades
      .map(up => up.value.price)
      .filter(notNullish)
      .reduce((acc, curr) => acc + curr, 0)

    const chargePerType = upgrade.quantityType === 'PARTY_SIZE' ? 'person' : 'reservation'
    if (chargePerType === 'reservation') {
      sum *= upgrade.quantity ?? 0
    }
    if (sum > 0) {
      groups.set(chargePerType, (groups.get(chargePerType) ?? 0) + sum)
    }
  }
  if (paymentRule === 'advanced_payment' && bookingCharge?.amount) {
    groups.set(bookingCharge.chargeType, (groups.get(bookingCharge.chargeType) ?? 0) + bookingCharge.amount)
  }

  return groups.entries()
}

function useGetTotals(field: Field<PaymentPolicyForm>) {
  const [upgrades, paymentRule, bookingCharge] = useWatchMany(field, ['bundledUpgrades', 'paymentRule', 'bookingCharge'])
  const chargeTypeChoices = useChargeChoices()

  return [...getTotals(upgrades, bookingCharge, paymentRule)]
    .map(([chargePerType, total]) => [chargeTypeChoices.find(choice => choice.id === chargePerType)?.label ?? '', total] as const)
    .sort(([lhs], [rhs]) => lhs.localeCompare(rhs))
}
