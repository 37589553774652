import { useMemo } from 'react'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  Grid,
  DividerLine,
  HStack,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Report,
  ReportLine,
  ReportPart,
  ReportSection,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import { SeatingAreasTestId } from '../SeatingAreas.testIds'
import type { ShiftSeatingAreas } from './ShiftSeatingAreas'
import type { SeatingAreasForm } from '../SeatingAreas.zod'

export interface ConflictProps {
  onClose: () => void
  field: Field<SeatingAreasForm['selection']>
  shiftSeatingAreas: ShiftSeatingAreas[]
}

export function ConflictModal({ onClose, field, shiftSeatingAreas }: ConflictProps) {
  const { formatMessage } = useLocales()
  const selection = useWatch(field)

  const shiftBookability = useMemo(
    () =>
      shiftSeatingAreas.map(shift => {
        const seatingAreaIds = shift.seatingAreas.map(({ id }) => id)
        const tables = shift.tables.map(({ id }) => id)

        const bookable =
          seatingAreaIds.length === 0
            ? selection
            : selection.filter(
                seating =>
                  (seating.value.isTable && tables.includes(seating.id)) || (!seating.value.isTable && seatingAreaIds.includes(seating.id))
              )

        const notBookable = selection
          .filter(seating => !bookable.some(({ id }) => id === seating.id))
          .map(({ label }) => label)
          .join(', ')

        return {
          id: shift.id,
          name: shift.name,
          seatingAreas: shift.seatingAreas,
          bookable: bookable.map(({ label }) => label).join(', '),
          notBookable,
        }
      }),
    [selection, shiftSeatingAreas]
  )

  return (
    <Modal data-test={SeatingAreasTestId.conflictModal} ariaLabel="main" width={700}>
      <ModalHeader
        onClose={e => {
          e.preventDefault()
          onClose()
        }}
      >
        <ModalTitle
          title={formatMessage(SeatingAreasLocales.conflictModalHeading)}
          subTitle={formatMessage(SeatingAreasLocales.conflictModalSubtext)}
        />
      </ModalHeader>

      <ModalBody>
        <VStack pt="xs" spacing="m">
          <DividerLine margin="none" />

          <HStack spacing="sm" mt="m">
            <VStack flexGrow="0" flexShrink="0" flexBasis="11.5em">
              <Text fontWeight="bold">{formatMessage(SeatingAreasLocales.conflictModalSetting)}</Text>
            </VStack>
            <VStack>
              <Text>{selection.map(({ label }) => label).join(', ')}</Text>
            </VStack>
          </HStack>

          <ReportSection
            title={
              <Grid columnGap="none" rowGap="lm" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="min-content">
                <Box maxWidth="100%" gridColumn="auto / span 6">
                  {formatMessage(SeatingAreasLocales.conflictModalShiftSettings)}
                </Box>
                <Box maxWidth="100%" gridColumn="auto / span 6">
                  {formatMessage(SeatingAreasLocales.conflictModalResults)}
                </Box>
              </Grid>
            }
          >
            <Report>
              <ReportPart
                caption={[
                  formatMessage(SeatingAreasLocales.modalShift),
                  formatMessage(SeatingAreasLocales.modalSeatingAreas),
                  <HStack spacing="xs" key="bookable">
                    <Text fontWeight="bold">{formatMessage(SeatingAreasLocales.conflictModalBookable)}</Text>
                    <Icon color="success" size="lg" name="VMSWeb-check" />
                  </HStack>,
                  <HStack spacing="xs" key="not-bookable">
                    <Text fontWeight="bold">{formatMessage(SeatingAreasLocales.conflictModalNotBookable)}</Text>
                    <Icon color="warning" size="lg" name="VMSWeb-warning" />
                  </HStack>,
                ]}
              >
                {shiftBookability.map(row => (
                  <ReportLine key={row.id} name={row.name} firstColWidth="25%" colWidth="25%">
                    <>
                      {row.seatingAreas.length === 0
                        ? formatMessage(SeatingAreasLocales.allSeatingAreas)
                        : row.seatingAreas.map(({ name }) => name).join(', ')}
                    </>
                    <>{row.bookable}</>
                    <>{row.notBookable}</>
                  </ReportLine>
                ))}
              </ReportPart>
            </Report>
          </ReportSection>

          <Text>{formatMessage(SeatingAreasLocales.conflictModalExplanation)}</Text>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <ModalActions>
          <Button
            variant="primary"
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
            data-test="ok-button"
          >
            OK
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
