import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { CollapsedPartySize, DefaultPartySize, PartySizeFields, type PartySizeProps } from './components'
import { PartySizeLocales } from './PartySize.locales'
import { PartySizeTestId } from './PartySize.testIds'

export function PartySize({ field, isPrivateEventAccessRule }: PartySizeProps) {
  const { formatMessage } = useLocales()

  return (
    <AccessRuleSlideoutSection
      data-test={PartySizeTestId.section}
      title={formatMessage(PartySizeLocales.title)}
      description={formatMessage(PartySizeLocales.description)}
      subCaption={formatMessage(PartySizeLocales.subCaption)}
      contentWhenCollapsed={<CollapsedPartySize field={field} />}
      defaultSettings={<DefaultPartySize />}
      sectionControllerName="partySize"
      field={field}
    >
      <PartySizeFields field={field} isPrivateEventAccessRule={isPrivateEventAccessRule} />
    </AccessRuleSlideoutSection>
  )
}
