import type { Shift } from '@sevenrooms/core/domain'
import { startOfDay, endOfDay, isSameDay, DateOnly, DateTimeInterval, TimeInterval, TimeOnly, DateTime } from '@sevenrooms/core/timepiece'
import type { DateRangePickerForm } from '@sevenrooms/core/ui-kit/form'
import { notNullish } from '@sevenrooms/core/utils'
import type { ScheduleForm } from './components/Schedule/Schedule.zod'

function getTimeRange(startTime: string, endTime: string) {
  const midnight = TimeOnly.from('00:00:00')
  const nextMidnight = TimeOnly.from('23:59:59')

  const startTimeOnly = TimeOnly.fromSafe(startTime)
  const endTimeOnly = TimeOnly.fromSafe(endTime)

  if (!startTimeOnly || !endTimeOnly || !startTimeOnly.isGreaterThan(endTimeOnly)) {
    return [TimeInterval.fromSafe(startTimeOnly, endTimeOnly)]
  }
  return [TimeInterval.fromSafe(midnight, endTimeOnly), TimeInterval.fromSafe(startTimeOnly, nextMidnight)]
}

function getOverlappingShiftsByInterval(overlappingShifts: Shift[]) {
  const midnight = TimeOnly.from('00:00:00')
  const nextMidnight = TimeOnly.from('23:59:59')

  return overlappingShifts
    .map(shift => {
      const { startTime, endTime } = shift
      if (!startTime || !endTime || startTime.isLessThan(endTime)) {
        return [{ interval: TimeInterval.fromSafe(startTime, endTime), shift }]
      }
      return [
        { interval: TimeInterval.fromSafe(midnight, endTime), shift },
        { interval: TimeInterval.fromSafe(startTime, nextMidnight), shift },
      ]
    })
    .flat()
}

function prepareScheduleDate(date: Date | null, time: string | null, isStart: boolean): Date | undefined {
  if (date == null) {
    return undefined
  }
  if (time == null) {
    return isStart ? startOfDay(date) : endOfDay(date)
  }
  return DateTime.fromDateAndTime(DateOnly.fromDate(date), TimeOnly.from(time))?.toJsDate()
}

function filterRelevantOverrides(shifts: Shift[], scheduleStart?: Date, scheduleEndTime?: Date): Shift[] {
  const selectedInterval = DateTimeInterval.fromSafe(DateTime.fromJsDateSafe(scheduleStart), DateTime.fromJsDateSafe(scheduleEndTime))
  const shiftMap = new Map()

  // Group shifts by persistent id
  shifts.forEach(shift => {
    const { persistentId } = shift
    const shiftsById = shiftMap.get(persistentId)
    if (!shiftsById) {
      shiftMap.set(persistentId, [shift])
    } else {
      shiftsById.push(shift)
    }
  })

  // Now if there are overrides for the same persistent id, filter out the base shifts.
  // If there are no overrides, return the base shifts.
  return [...shiftMap.values()]
    .map(shifts => {
      const baseShifts: Shift[] = []
      const overrideShifts: Shift[] = []
      shifts.forEach((shift: Shift) => {
        // Quick n easy way of checking if the shift is a base shift - overrides don't have days of the week
        if ('dayOfWeek' in shift) {
          baseShifts.push(shift)
        } else {
          overrideShifts.push(shift)
        }
      })
      const filteredOverrides = overrideShifts.filter(shift =>
        DateTimeInterval.fromJsDateSafe(shift.startDateTime?.toJsDate(), shift.endDateTime?.toJsDate())?.isOverlapping(selectedInterval)
      )
      if (filteredOverrides.length > 0) {
        return filteredOverrides
      }
      return baseShifts
    })
    .filter(notNullish)
    .flat()
}

function filterExcludedDates(shifts: Shift[], dateRange: DateTimeInterval | undefined): Shift[] {
  return shifts.filter(shift => {
    if (!shift.excludedDates || !dateRange) {
      return true
    }

    const { excludedDates } = shift
    const rangeDateTimes = dateRange.getIntervalList()
    return !rangeDateTimes.every(rdt => excludedDates.some(ed => ed.isEqualTo(rdt.toDateOnly())))
  })
}

export function getOverlappingShifts(shifts: Shift[], schedule: ScheduleForm, selectedDate?: Date): Shift[] {
  const scheduleStart = prepareScheduleDate(schedule.dateRange.startDate, schedule.startTime, true)
  const scheduleEnd = prepareScheduleDate(schedule.dateRange.endDate, schedule.endTime, false)
  const dateRange = DateTimeInterval.fromJsDateSafe(scheduleStart, scheduleEnd)
  let overlappingShifts = shifts.filter(shift =>
    dateRange?.isOverlapping(DateTimeInterval.fromSafe(shift.startDateTime, shift.endDateTime))
  )

  const selectedStart = prepareScheduleDate(selectedDate ?? null, schedule.startTime, true)
  const selectedEnd = prepareScheduleDate(selectedDate ?? null, schedule.endTime, false)
  overlappingShifts = filterRelevantOverrides(overlappingShifts, selectedStart, selectedEnd)

  // if schedule is single-day, then filter single day shift overrides
  if (scheduleStart && scheduleEnd && isSameDay(scheduleStart, scheduleEnd)) {
    const overlappingShiftsNarrowed = new Map()
    overlappingShifts.forEach(shift => {
      if (
        !overlappingShiftsNarrowed.has(shift.shiftCategory) ||
        (shift.startDateTime && shift.endDateTime && isSameDay(shift.startDateTime.toJsDate(), shift.endDateTime.toJsDate()))
      ) {
        overlappingShiftsNarrowed.set(shift.shiftCategory, shift)
      }
    })
    overlappingShifts = [...overlappingShiftsNarrowed.values()]
  } else {
    overlappingShifts = overlappingShifts.filter(
      shift => shift.dayOfWeek == null || shift.dayOfWeek.some((checked, idx) => checked && schedule.selectedDays.includes(idx))
    )
  }

  // filter out any shifts that entirely overlap with excluded dates
  overlappingShifts = filterExcludedDates(overlappingShifts, dateRange)

  if (schedule.accessTimeType === 'ALL' || schedule.restrictToShifts) {
    overlappingShifts = overlappingShifts.filter(shift => shift.shiftCategory && schedule.shiftCategories.includes(shift.shiftCategory))
  }

  let overlappingShiftsByInterval = getOverlappingShiftsByInterval(overlappingShifts)
  if (schedule.accessTimeType === 'SPECIFIC') {
    const specificTimes = schedule.specificTimes.map(t => TimeInterval.fromIsoWithSize(t, { minutes: 15 })).filter(notNullish)
    overlappingShiftsByInterval = overlappingShiftsByInterval.filter(({ interval }) =>
      specificTimes.some(specificTime => specificTime.isOverlapping(interval))
    )
  } else if (schedule.accessTimeType === 'TIME_RANGE' && schedule.startTime && schedule.endTime) {
    const timeRange = getTimeRange(schedule.startTime, schedule.endTime)
    overlappingShiftsByInterval = overlappingShiftsByInterval.filter(({ interval }) =>
      timeRange.some(range => range?.isOverlapping(interval))
    )
  }

  const shiftIds = overlappingShiftsByInterval.map(({ shift }) => shift.id)
  return overlappingShiftsByInterval.map(({ shift }) => shift).filter(({ id }, idx) => !shiftIds.includes(id, idx + 1))
}

export function getClonedDateRange(dateRange: DateRangePickerForm): DateRangePickerForm {
  const { startDate, endDate, isInfinite } = dateRange
  const copyDateRange = { ...dateRange }
  if (startDate && startDate < new Date()) {
    copyDateRange.startDate = new Date()
  }
  if (!isInfinite && endDate && endDate < new Date()) {
    copyDateRange.endDate = new Date()
    copyDateRange.isInfinite = false
  }
  return copyDateRange
}

export function areObjectsEqual(object1: { [key: string]: unknown } | {}, object2: { [key: string]: unknown } | {}) {
  if (Object.keys(object1).length !== Object.keys(object2).length) {
    return false
  }

  for (const key in object1) {
    if (!Object.prototype.hasOwnProperty.call(object1, key)) {
      continue
    }

    const val1 = (object1 as { [key: string]: unknown })[key]
    const val2 = (object2 as { [key: string]: unknown })[key]
    if (!areValuesEqual(val1, val2)) {
      return false
    }
  }
  return true
}

export function areArraysEqual(array1: unknown[], array2: unknown[]) {
  if (array1.length !== array2.length) {
    return false
  }

  let result = true
  array1.forEach((val1: unknown, index: number) => {
    if (!result) {
      return
    }

    const val2 = array2[index]
    if (!areValuesEqual(val1, val2)) {
      result = false
    }
  })

  return result
}

function areValuesEqual(val1: unknown, val2: unknown) {
  if (Array.isArray(val1)) {
    if (!Array.isArray(val2) || !areArraysEqual(val1, val2)) {
      return false
    }
  } else if (val1 instanceof Date && val2 instanceof Date) {
    // Only check for date, not time
    const dateTime1 = DateOnly.fromDate(val1)
    const dateTime2 = DateOnly.fromDate(val2)
    if (!dateTime1.isEqualTo(dateTime2)) {
      return false
    }
  } else if (
    typeof val1 === 'object' &&
    typeof val2 === 'object' &&
    val1 !== undefined &&
    val1 !== null &&
    val2 !== undefined &&
    val2 !== null
  ) {
    if (!areObjectsEqual(val1, val2)) {
      return false
    }
  } else if (val1 !== val2) {
    return false
  }

  return true
}
