import { type ForwardedRef, forwardRef, useMemo, type PropsWithChildren } from 'react'
import type { AccessRuleInput } from '@sevenrooms/core/api'
import { type AccessRule, type ShiftCategory, getVenueTaxRates } from '@sevenrooms/core/domain'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { isSeatingAreaToTables } from './AccessRule.types'
import { AccessRulesSlideoutForm, type AccessRulesSlideoutRef } from './AccessRulesSlideoutForm'
import type { AccessRulesSlideoutData } from './useAccessRulesSlideoutData'

export interface AccessRulesSlideoutProps {
  mode?: 'new-item'
  selectedDay: string
  onClose: () => void
  onRuleSave: (accessRule: AccessRule) => void
  onRuleDelete: () => void
  startDate: Date
  startTimeDisplay?: string
  endTimeDisplay?: string
  shiftCategories: ShiftCategory[]
  accessRulesSlideoutData: AccessRulesSlideoutData
  accessRule?: AccessRule
  disabledFields?: string[]

  // Used by Perks to override regular onRuleSave
  onRuleSaveOverride?: ({ id, accessRuleInput }: { id: string; accessRuleInput: AccessRuleInput }) => void
}

export const AccessRulesSlideout = forwardRef<AccessRulesSlideoutRef, PropsWithChildren<AccessRulesSlideoutProps>>(
  AccessRulesSlideoutComponent
)

function AccessRulesSlideoutComponent(props: PropsWithChildren<AccessRulesSlideoutProps>, ref: ForwardedRef<AccessRulesSlideoutRef>) {
  const { venueId } = useVenueContext()
  const { accessRulesSlideoutData } = props

  const experiences = useMemo(() => Object.values(accessRulesSlideoutData.experiences ?? {}), [accessRulesSlideoutData.experiences])
  const seatingAreaToTables = useMemo(
    () => accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables.filter(isSeatingAreaToTables) ?? [],
    [accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables]
  )
  const taxRates = useMemo(() => getVenueTaxRates(accessRulesSlideoutData.taxRates, venueId), [accessRulesSlideoutData, venueId])

  const tagGroups = useMemo(
    () => new Map(accessRulesSlideoutData.reservationTagGroups.map(tagGroup => [tagGroup.id, tagGroup])),
    [accessRulesSlideoutData.reservationTagGroups]
  )
  const clientTagGroups = useMemo(
    () => new Map(accessRulesSlideoutData.clientTagGroups.map(tagGroup => [tagGroup.id, tagGroup])),
    [accessRulesSlideoutData.clientTagGroups]
  )
  return (
    <AccessRulesSlideoutForm
      ref={ref}
      context={{
        accessRule: props.accessRule,
        allShifts: accessRulesSlideoutData.allShifts,
        audienceHierarchy: accessRulesSlideoutData.audienceHierarchy,
        defaultBookingPolicyId: accessRulesSlideoutData.defaultPolicies.bookingPolicyId ?? 'default',
        defaultCancelPolicyId: accessRulesSlideoutData.defaultPolicies.cancelPolicyId ?? 'default',
        experiences,
        policies: accessRulesSlideoutData.policies,
        seatingAreaToTables,
        tagGroups,
        taxRates,
        upsells: accessRulesSlideoutData.upsells,
        widgetSettings: accessRulesSlideoutData.reservationWidgetSettings,
        clientTagGroups,
      }}
      params={{ date: props.selectedDay, venueId }}
      startDate={props.startDate}
      startTimeDisplay={props.startTimeDisplay}
      endTimeDisplay={props.endTimeDisplay}
      shiftCategories={props.shiftCategories}
      onClose={props.onClose}
      onRuleSave={props.onRuleSave}
      onRuleDelete={props.onRuleDelete}
      mode={props.mode}
      onRuleSaveOverride={props.onRuleSaveOverride}
      disabledFields={props.disabledFields}
    >
      {props.children}
    </AccessRulesSlideoutForm>
  )
}
