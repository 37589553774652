import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../AccessRules.locales'

export interface SwitchSlideoutProps {
  switchSlideoutCallback: () => void
  'data-test': string
}

export function SwitchSlideout({ switchSlideoutCallback, 'data-test': dataTest }: SwitchSlideoutProps) {
  const { formatMessage } = useLocales()

  const message = formatMessage(accessRulesMessages.switchSlideout, {
    a: (chunks: string[]) => (
      <Button variant="tertiary" data-test={dataTest} onClick={switchSlideoutCallback} noPadding>
        {chunks}
      </Button>
    ),
  })

  return (
    <Box textAlign="center" p="m">
      <Text color="secondaryFont">{message}</Text>
    </Box>
  )
}
