/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import { type ReactNode, type PropsWithChildren, useState, useCallback } from 'react'
import { type Field, keepEdited, useFormContext, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useBlink } from '@sevenrooms/core/ui-kit/animation'
import { Button, CollapseButton } from '@sevenrooms/core/ui-kit/form'
import { useGetLatest } from '@sevenrooms/core/ui-kit/hooks'
import {
  Box,
  DividerLine,
  Flex,
  HStack,
  useSectionController,
  SlideOutSubsection,
  type SlideOutSubsectionProps,
  Window,
} from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { type AccessRuleForm, type AccessRuleSection, getAccessRuleDefaults, getInitialAccessRule } from '../../AccessRule.zod'
import { accessRulesMessages } from '../../AccessRules.locales'
import { useAccessRuleContext } from './AccessRuleContext'
import { AccessRuleModal } from './AccessRuleModal'
import { SharedTestId } from './shared.testIds'
import { useIsDefault } from './useIsDefault'
import { StatusTag } from './useStatusTag'

export interface AccessRuleSlideoutSectionProps<T extends AccessRuleSection> extends SlideOutSubsectionProps {
  defaultSettings: ReactNode
  defaultSettingsFooter?: ReactNode
  contentWhenCollapsed: ReactNode
  sectionControllerName: T
  field: Field<AccessRuleForm[T]>
  description?: ReactNode
  subCaption?: ReactNode
  onEnterEditMode?: () => void
  'data-test'?: string
  disableEdit?: boolean
}

export function AccessRuleSlideoutSection<T extends AccessRuleSection>({
  defaultSettings,
  defaultSettingsFooter,
  contentWhenCollapsed,
  sectionControllerName,
  children,
  field,
  description,
  subCaption,
  onEnterEditMode,
  'data-test': testId,
  disableEdit,
  ...props
}: PropsWithChildren<AccessRuleSlideoutSectionProps<T>>) {
  const { formatMessage } = useLocales()
  const { trigger } = useFormContext()
  const context = useAccessRuleContext()
  const accessRuleDefaults = getAccessRuleDefaults({ ...context })
  const initialAccessRule = getInitialAccessRule({ ...context })
  const { isDefault } = useIsDefault(field, accessRuleDefaults[sectionControllerName])
  const { isDefault: isInitial } = useIsDefault(field, initialAccessRule[sectionControllerName])
  const backup = useGetLatest(useWatch(field))
  const [isDefaultSettingsConfirmOpen, setIsDefaultSettingsConfirmOpen] = useState(false)
  const [isInEditMode, setIsInEditMode] = useState(!isDefault)
  const [statusTagIsActive, showDefaultMessageRestored] = useBlink()

  const { tryToggleCollapse, collapsed } = useSectionController(sectionControllerName, {
    initiallyCollapsed: true,
    canCollapse: async () => trigger(sectionControllerName, { shouldFocus: true }),
    onToggleCollapse: (collapse: boolean) => {
      if (collapse && isInEditMode) {
        field.commit(keepEdited)
        const isDefault = _.isEqual(accessRuleDefaults[sectionControllerName], backup())
        if (isDefault) {
          setIsInEditMode(false)
          showDefaultMessageRestored()
        }
      }
    },
  })

  const restoreDefaultSettings = useCallback(async () => {
    field.set(accessRuleDefaults[sectionControllerName])
    setIsDefaultSettingsConfirmOpen(false)
    if (!(await tryToggleCollapse())) {
      field.set(backup())
    }
  }, [accessRuleDefaults, sectionControllerName, field, tryToggleCollapse, setIsDefaultSettingsConfirmOpen, backup])

  const cancelChanges = useCallback(async () => {
    field.clear(null, keepEdited)
    if (!(await tryToggleCollapse())) {
      field.set(backup())
    }
  }, [field, tryToggleCollapse, backup])

  const enterEditMode = useCallback(() => {
    onEnterEditMode?.()
    setIsInEditMode(true)
  }, [onEnterEditMode, setIsInEditMode])

  const hideTable = isInEditMode || !isDefault
  return (
    <SlideOutSubsection
      data-test={testId}
      onClick={tryToggleCollapse}
      {...props}
      collapsed={collapsed}
      actions={<WithCollapse actions={<StatusTag isActive={statusTagIsActive} isEdited={!isInitial} />} collapsed={collapsed} />}
      description={<SectionHeaderSubtext subCaption={subCaption} description={description} shouldHideDescription={collapsed} />}
    >
      <Box pb={collapsed && isDefault ? 'lm' : undefined}>
        {collapsed ? (
          !isDefault && (
            <>
              <Box pl="lm" pr="lm" pt="sm">
                <DividerLine margin="none" />
              </Box>
              <Box data-test={SharedTestId.collapsedContent} pt="sm" pr="lm" pl="lm" pb="lm">
                {contentWhenCollapsed}
              </Box>
            </>
          )
        ) : hideTable ? (
          <>
            <Box p="lm">{children}</Box>
            <Flex pb="lm" pr="lm" justifyContent="space-between">
              <Flex>
                {!isDefault && (
                  <Button
                    data-test={SharedTestId.restoreDefaultSettings}
                    variant="tertiary"
                    onClick={() => setIsDefaultSettingsConfirmOpen(true)}
                    disabled={disableEdit}
                  >
                    {formatMessage(accessRulesMessages.defaultSettingsButton)}
                  </Button>
                )}
              </Flex>
              <HStack spacing="s">
                <Button data-test={SharedTestId.cancelButton} variant="tertiary" onClick={cancelChanges} disabled={disableEdit}>
                  {formatMessage(commonMessages.cancel)}
                </Button>
                <Button data-test={SharedTestId.doneButton} variant="secondary" onClick={tryToggleCollapse} disabled={disableEdit}>
                  {formatMessage(commonMessages.done)}
                </Button>
              </HStack>
            </Flex>
          </>
        ) : (
          <DefaultSettingsDisplay enterEditMode={enterEditMode} footer={defaultSettingsFooter} disableEdit={disableEdit}>
            {defaultSettings}
          </DefaultSettingsDisplay>
        )}
      </Box>
      <Window active={isDefaultSettingsConfirmOpen}>
        <AccessRuleModal
          onClose={() => setIsDefaultSettingsConfirmOpen(false)}
          onConfirm={restoreDefaultSettings}
          modalBody={defaultSettings}
        />
      </Window>
    </SlideOutSubsection>
  )
}

interface WithCollapseProps {
  actions?: ReactNode
  onToggle?: (collapsed: boolean) => void
  collapsed: boolean
}

function WithCollapse({ actions, onToggle, collapsed }: WithCollapseProps) {
  return (
    <>
      {actions}
      <Box ml={actions ? 's' : undefined}>
        <CollapseButton data-test={SharedTestId.subsectionCollapse} size="s" collapsed={collapsed} onToggle={onToggle} />
      </Box>
    </>
  )
}

interface SectionHeaderSubtextProps {
  subCaption?: ReactNode
  description?: ReactNode
  shouldHideDescription?: boolean
}

function SectionHeaderSubtext({ subCaption, description, shouldHideDescription }: SectionHeaderSubtextProps) {
  return (
    <>
      {subCaption && (
        <Box mb="xs">
          <Text fontSize="m">{subCaption}</Text>
        </Box>
      )}

      {description && !shouldHideDescription && <SecondaryText>{description}</SecondaryText>}
    </>
  )
}

interface DefaultSettingsDisplayProps {
  enterEditMode: () => void
  footer?: ReactNode
  disableEdit?: boolean
}
function DefaultSettingsDisplay({ children, footer, enterEditMode, disableEdit }: PropsWithChildren<DefaultSettingsDisplayProps>) {
  const { formatMessage } = useLocales()
  return (
    <>
      <Box data-test={SharedTestId.defaultSettings} p="lm">
        {children}
      </Box>
      <Flex justifyContent="space-between">
        <Box pb="lm" pl="lm">
          {footer}
        </Box>
        <Box pb="lm" pr="lm">
          <Button data-test={SharedTestId.editButton} variant="secondary" onClick={enterEditMode} disabled={disableEdit}>
            {formatMessage(commonMessages.edit)}
          </Button>
        </Box>
      </Flex>
    </>
  )
}
