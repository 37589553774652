import type { AccessRule, NewSeatingAreaToTables, SeatingAreaToTables, Policy } from '@sevenrooms/core/domain'

export interface AudienceHierarchy {
  name: string
  value: string
  children: AudienceHierarchy[] | null
}

export interface SeatingArea {
  allTableInventoryItems: TableItem[]
  venueSeatingAreas: VenueSeatingArea[]
  // Not so new anymore
  newSeatingAreaCodesToTables: NewSeatingAreaToTables[]
  seatingAreaCodesToTables: SeatingAreaToTables[]
}

export interface TableItem {
  id: string
  itemCode: string
  partySizeMax: number
  partySizeMin: number
  partySizeRangeDisplay: string
}

export interface VenueSeatingArea {
  id: string
  name: string
  code: string
  localId: unknown | null
  sortOrder: number
}

export function isSeatingAreaToTables(map: SeatingAreaToTables | NewSeatingAreaToTables): map is SeatingAreaToTables {
  return map.id != null && map.name != null
}

export interface Upsells {
  categories: Record<string, UpsellCategory>
  inventory: Inventory[]
}

export interface UpsellCategory {
  label: string
  minQuantityType: UpsellQuantityType
}

export enum UpsellQuantityTypeEnum {
  NONE_REQUIRED = 'NONE_REQUIRED',
  UNLIMITED = 'UNLIMITED',
  NUMBER_OF_GUESTS = 'NUMBER_OF_GUESTS',
  SPECIFIC_NUMBER = 'SPECIFIC_NUMBER',
}

export type UpsellQuantityType = keyof typeof UpsellQuantityTypeEnum

export interface Inventory {
  categoryId: string
  description: string
  price: number
  id: string
  name: string
}

export interface TagGroup {
  id: string
  tags: string[]
  tagNameDisplays: Record<string, string>
  privacy: string
  name: string
  nameDisplay: string
  colorHex: string
}

export interface DefaultPolicies {
  bookingPolicy: string
  cancelPolicy: string
  bookingPolicyId?: string
  cancelPolicyId?: string
}

export interface AccessRuleDefaultParams {
  defaultBookingPolicyId: string
  defaultCancelPolicyId: string
  accessRule?: AccessRule
  audienceHierarchy?: AudienceHierarchy[]
  upsells?: Upsells
  venueCurrencyCode?: string
  tagGroups?: Map<string, TagGroup>
  policies: Policy[]
  clientTagGroups: Map<string, TagGroup>
}
