import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { DefaultSettingsReportSection } from '../../shared'
import { GuestDurationPickerLocales } from '../GuestDurationPicker.locales'
import { GuestDurationPickerTestId } from '../GuestDurationPicker.testIds'

export function DefaultGuestDurationPicker() {
  const { formatMessage } = useLocales()

  return (
    <DefaultSettingsReportSection data-test={GuestDurationPickerTestId.defaultsTable}>
      <Report>
        <ReportPart>
          <ReportLine name={formatMessage(GuestDurationPickerLocales.specifyDurationLabel)}>
            <Text>{formatMessage(commonMessages.no)}</Text>
          </ReportLine>
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
