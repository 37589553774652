import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { SharedTestId } from '../../shared/shared.testIds'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { DefaultPaymentPolicy } from './DefaultPaymentPolicy'

export interface PaymentPolicyDefaultsModalProps {
  onDone?: () => void
}

export function PaymentPolicyDefaultsModal({ onDone }: PaymentPolicyDefaultsModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal data-test={SharedTestId.defaultSettingsModal} ariaLabel="main" width={700}>
      <ModalHeader onClose={onDone}>
        <ModalTitle
          title={formatMessage(PaymentPolicyLocales.defaultSettingsModalTitle)}
          subTitle={formatMessage(PaymentPolicyLocales.defaultSettingsModalSubtext, {
            strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
          })}
        />
      </ModalHeader>

      <ModalBody>
        <DefaultPaymentPolicy />
      </ModalBody>

      <ModalFooter>
        <ModalActions>
          <Button data-test={SharedTestId.defaultSettingsDone} variant="primary" onClick={onDone}>
            {formatMessage(commonMessages.done)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
