import type { AccessRuleAccessTime, Shift, ShiftCategory } from '@sevenrooms/core/domain'
import { commonMessages, type FormatMessage } from '@sevenrooms/core/locales'
import { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { notNullish } from '@sevenrooms/core/utils'
import * as utils from '../ViewMode/utils'
import { ScheduleLocales } from './Schedule.locales'

export function getShiftCategoryName(category: ShiftCategory) {
  const [first, ...rest] = category === 'LEGACY' ? 'night'.toLocaleLowerCase() : category.toLocaleLowerCase()
  return [first?.toLocaleUpperCase(), ...rest].join('')
}

export function formatShifts(shiftCategories: ShiftCategory[], allShifts: Shift[], formatMessage: FormatMessage) {
  return shiftCategories.length === 0
    ? `${formatMessage(commonMessages.any)} (${allShifts.length})`
    : utils
        .sortShiftCategories(shiftCategories)
        .map(shiftCategory => {
          const name = getShiftCategoryName(shiftCategory)
          const count = allShifts.filter(shift => shift.shiftCategory === shiftCategory).length
          return `${name} (${count})`
        })
        .join(', ')
}

export function formatDates(formatMessage: FormatMessage, startDate: Date, isInfinite?: boolean, endDate?: Date | null) {
  const formattedStartDate = DateOnly.fromDate(startDate).formatNYearNMonthNDay()
  const formattedEndDate = DateOnly.fromJsDateSafe(endDate)?.formatNYearNMonthNDay()

  if (isInfinite) {
    return formatMessage(ScheduleLocales.startDateToIndefinite, { date: formattedStartDate })
  } else if (formattedStartDate === formattedEndDate || endDate == null) {
    return formattedStartDate
  }
  return `${formattedStartDate} - ${formattedEndDate}`
}

export function formatTimes(
  accessTimeType: AccessRuleAccessTime,
  specificTimes: string[],
  allShifts: Shift[],
  shiftCategories: ShiftCategory[],
  startTime?: TimeOnly,
  endTime?: TimeOnly
) {
  if (accessTimeType === 'TIME_RANGE') {
    return `${startTime?.formatSTime()} - ${endTime?.formatSTime()}`
  } else if (accessTimeType === 'SPECIFIC') {
    return specificTimes.map(time => TimeOnly.fromSafe(time)?.formatSTime()).join(', ')
  } else if (accessTimeType === 'ALL') {
    const startEndTimesByShiftDisplay = allShifts
      .map(s =>
        s.shiftCategory != null && s.startTime != null && s.endTime != null
          ? {
              shiftCategory: s.shiftCategory,
              times: [s.startTime.formatSTime(), s.endTime.formatSTime()],
            }
          : null
      )
      .filter(notNullish)
      .filter(s => shiftCategories.includes(s.shiftCategory))
    return utils.buildValueByShiftTimesDisplay(startEndTimesByShiftDisplay ?? [], [
      ...new Set(allShifts.map(shift => shift.shiftCategory).filter(notNullish)),
    ])
  }
  throw Error('Never!')
}
