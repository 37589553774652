import { useCallback } from 'react'
import { AccessRuleTagRestrictionEnum, type AccessRuleTimeUnitType } from '@sevenrooms/core/domain'
import { type Field, useController, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { BasicButton, Button, Label, MultiSelectTree, Radio, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, DividerLine, HStack, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { checkTimeBeforeWarning, TimeBefore, useAccessRuleContext, type TimeBeforeForm } from '../../shared'
import { CutoffTimeWarningTooltip } from '../../shared/CutoffTimeWarningTooltip'
import { BookingChannelsLocales } from '../BookingChannels.locales'
import { BookingChannelsTestId } from '../BookingChannels.testIds'
import { CreateTagSelector } from './CreateTagSelector'
import type { BookingWindowForm } from '../../BookingWindow/BookingWindow.zod'
import type { BookingChannelsForm } from '../BookingChannels.zod'

export interface BookingChannelsFieldsProps {
  field: Field<BookingChannelsForm>
  bookingWindowField: Field<BookingWindowForm>
  audienceOptions: TreeNode[]
  disableEdit?: boolean
}

interface AudienceTierProps {
  index: number
  hasMultiple?: boolean
  audienceOptions: TreeNode[]
  field: Field<BookingChannelsForm[number]>
  bookingWindowCutoffTime: TimeBeforeForm
  onRemove: (index: number) => void
  disableEdit?: boolean
  disableRemove?: boolean
}

function AddAudienceTierTooltip() {
  const { formatMessage } = useLocales()

  const content = (
    <VStack spacing="s">
      <Text color="lightFont" fontWeight="bold">
        {formatMessage(BookingChannelsLocales.addTier)}
      </Text>
      <Text color="lightFont">{formatMessage(BookingChannelsLocales.buttonTooltipBody1)}</Text>
      <Text color="lightFont">{formatMessage(BookingChannelsLocales.buttonTooltipBody2)}</Text>
      <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/360025628471-Access-Rules">
        {formatMessage(BookingChannelsLocales.buttonTooltipLinkText)}
      </Anchor>
    </VStack>
  )

  return (
    <Tooltip content={content}>
      <BasicButton>
        <Icon name="VMSWeb-info" color="primaryIcons" size="lg" />
      </BasicButton>
    </Tooltip>
  )
}

function AudienceTier({
  index,
  hasMultiple,
  field,
  audienceOptions,
  bookingWindowCutoffTime,
  onRemove,
  disableEdit,
  disableRemove,
}: AudienceTierProps) {
  const { formatMessage } = useLocales()
  const units: AccessRuleTimeUnitType[] = ['HOURS', 'DAYS', 'WEEKS', 'MONTHS']

  const startTime = useWatch(field.prop('startTime'))
  const hasBookingChannelsWarning = hasMultiple && checkTimeBeforeWarning(startTime, bookingWindowCutoffTime)
  const removeHandler = useCallback(() => {
    onRemove(index)
    field.remove()
  }, [field, index, onRemove])
  const tagRestrictionController = useController(field.prop('tagRestriction'))
  const { venueSettings } = useAppContext()
  const { clientTagGroups } = useAccessRuleContext()
  const bookClientTagsController = useController(field.prop('bookClientTags.tags'))
  const testId = `${BookingChannelsTestId.bookingChannelSection}-${index}`
  return (
    <VStack spacing="m" data-test={testId}>
      <VStack spacing="s">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">{formatMessage(BookingChannelsLocales.audienceTier, { num: index + 1 })}</Text>
          {hasMultiple && !disableRemove && (
            <BasicButton onClick={removeHandler} data-test={`${testId}-remove`}>
              <Icon name="VMSWeb-close" color="primaryIcons" size="lg" />
            </BasicButton>
          )}
        </HStack>
        <Box mt="s">
          <Text>{formatMessage(BookingChannelsLocales.showThisOn)}</Text>
        </Box>
        <MultiSelectTree<string>
          disabled={disableEdit}
          placeholder={formatMessage(BookingChannelsLocales.searchAudiences)}
          treeData={audienceOptions}
          field={field.prop('selected')}
          id={`${testId}-selected`}
          data-test={`${testId}-selected`}
        />
        {venueSettings.isExclusiveArAccessEnabled && (
          <VStack spacing="s" mt="m">
            <Box>
              <Radio
                value={AccessRuleTagRestrictionEnum.NONE}
                checked={tagRestrictionController.field.value === AccessRuleTagRestrictionEnum.NONE}
                onClick={() => tagRestrictionController.field.onChange(AccessRuleTagRestrictionEnum.NONE)}
              >
                {formatMessage(BookingChannelsLocales.inventoryAvailabilityAvailableLabel)}
              </Radio>
            </Box>
            <Box>
              <Radio
                value={AccessRuleTagRestrictionEnum.BOOK}
                checked={tagRestrictionController.field.value === AccessRuleTagRestrictionEnum.BOOK}
                onClick={() => tagRestrictionController.field.onChange(AccessRuleTagRestrictionEnum.BOOK)}
              >
                {formatMessage(BookingChannelsLocales.inventoryAvailabilityVisibleOnlyByGuestsWithCertainClientTagsLabel)}
              </Radio>
              {tagRestrictionController.field.value === AccessRuleTagRestrictionEnum.BOOK && (
                <CreateTagSelector
                  field={field.prop('bookClientTags.tags')}
                  clientTagGroups={clientTagGroups}
                  whoCanBook={bookClientTagsController.field.value.map(tag => tag.label).join(', ')}
                />
              )}
            </Box>
            <Box>
              <Radio
                value={AccessRuleTagRestrictionEnum.VIEW}
                checked={tagRestrictionController.field.value === AccessRuleTagRestrictionEnum.VIEW}
                onClick={() => tagRestrictionController.field.onChange(AccessRuleTagRestrictionEnum.VIEW)}
              >
                {formatMessage(BookingChannelsLocales.inventoryAvailabilityVisibleButBookableByGuestsWithCertainClientTagsLabel)}
              </Radio>
              {tagRestrictionController.field.value === AccessRuleTagRestrictionEnum.VIEW && (
                <CreateTagSelector
                  field={field.prop('viewClientTags.tags')}
                  clientTagGroups={clientTagGroups}
                  whoCanBook={formatMessage(BookingChannelsLocales.allGuests)}
                />
              )}
            </Box>
          </VStack>
        )}
      </VStack>
      {hasMultiple && (
        <VStack spacing="s" ml="m">
          <Label
            primary={formatMessage(BookingChannelsLocales.startTimeLabel)}
            secondary={formatMessage(BookingChannelsLocales.startTimeSubLabel)}
          >
            <TimeBefore
              disabled={disableEdit}
              field={field.prop('startTime')}
              units={units}
              data-test={`${testId}-start-time`}
              extra={hasBookingChannelsWarning && <CutoffTimeWarningTooltip hasBookingChannelsWarning />}
            />
          </Label>
        </VStack>
      )}
      {hasMultiple && <DividerLine color="dividerLines" />}
    </VStack>
  )
}

export function BookingChannelsFields({ field, bookingWindowField, audienceOptions, disableEdit }: BookingChannelsFieldsProps) {
  const { formatMessage } = useLocales()
  const numTiers = field.size()

  const bookingWindowCutoffTime = useWatch(bookingWindowField.prop('cutoffTime'))
  const bookingChannels = useWatch(field)

  // Disable editing for Early Access Audience Tiers
  const { accessRule } = useAccessRuleContext()
  const audienceTierIndicesToDisable = new Set()
  accessRule?.audienceTiers.forEach((audienceTier, index) => {
    if (audienceTier.isEarlyAccess) {
      audienceTierIndicesToDisable.add(index)
    }
  })

  const addTier = useCallback(() => {
    field.append({
      audienceTierId: null,
      selected: [],
      tagRestriction: AccessRuleTagRestrictionEnum.NONE,
      viewClientTags: { tags: [], hasDeletedTags: false },
      bookClientTags: { tags: [], hasDeletedTags: false },
      startTime: {
        count: null,
        unit: 'DAYS',
        beforeTime: '0',
      },
    })
    if (field.size() === 2) {
      bookingWindowField.prop('startTime').set({
        count: null,
        unit: 'DAYS',
        beforeTime: '0',
      })
    }
  }, [field, bookingWindowField])

  const removeTier = useCallback(
    index => {
      if (numTiers === 2) {
        const remainingTier = bookingChannels[index === 0 ? 1 : 0]
        if (remainingTier) {
          bookingWindowField.prop('startTime').set(remainingTier.startTime)
        }
      }
    },
    [bookingChannels, bookingWindowField, numTiers]
  )

  return (
    <VStack spacing="m">
      <DividerLine color="dividerLines" />
      {field.map((tier, _, index) => (
        <AudienceTier
          disableEdit={disableEdit || audienceTierIndicesToDisable.has(index)}
          disableRemove={audienceTierIndicesToDisable.has(index)}
          field={tier}
          bookingWindowCutoffTime={bookingWindowCutoffTime}
          index={index}
          hasMultiple={numTiers > 1}
          audienceOptions={audienceOptions}
          onRemove={removeTier}
          key={index}
        />
      ))}
      {numTiers < 3 && (
        <HStack spacing="s">
          <Button disabled={disableEdit} variant="secondary" icon="VMSWeb-add" onClick={addTier} data-test={BookingChannelsTestId.addTier}>
            {formatMessage(BookingChannelsLocales.addTier)}
          </Button>
          <AddAudienceTierTooltip />
        </HStack>
      )}
    </VStack>
  )
}
