import type { AccessRule, Experience, Policy, ReservationWidget, Shift, TaxRate, SeatingAreaToTables } from '@sevenrooms/core/domain'
import { createContext } from '@sevenrooms/core/utils'
import type { AudienceHierarchy, TagGroup, Upsells } from '../../AccessRule.types'

export type AccessRuleWidgetSettings = Pick<
  ReservationWidget.Settings,
  'buttonId' | 'colorPrimary' | 'colorError' | 'font' | 'fontsColorButton'
>

export interface AccessRuleContext {
  accessRule?: AccessRule
  allShifts: Shift[]
  shifts: Shift[]
  audienceHierarchy: AudienceHierarchy[]
  defaultBookingPolicyId: string
  defaultCancelPolicyId: string
  experiences: Experience[]
  policies: Policy[]
  seatingAreaToTables: SeatingAreaToTables[]
  tagGroups: Map<string, TagGroup>
  clientTagGroups: Map<string, TagGroup>
  taxRates: TaxRate[]
  upsells: Upsells
  widgetSettings: AccessRuleWidgetSettings
}

export const [AccessRuleContextProvider, useAccessRuleContext] = createContext<AccessRuleContext>()
