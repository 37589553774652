import * as React from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Button, type RadioChoice, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle, Window } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '@sevenrooms/mgr-access-rules-slideout/AccessRules.locales'
import { RecurringDecisionTestId } from './RecurringDecision.testIds'

export type Recurring = 'override' | 'following' | 'all'
export type RecurringDecisionMode = 'edit' | 'delete'

export interface RecurringDecisionProps {
  active: boolean
  name?: string
  selectedDateOnly: DateOnly
  mode?: RecurringDecisionMode
  isSelectedDayToday: boolean
  setIsActive: (value: boolean) => void
  onSuccess: (decision: Recurring) => void
}

export function RecurringDecision(props: RecurringDecisionProps) {
  const { formatMessage } = useLocales()
  const { selectedDateOnly, mode, name = '' } = props
  const [decision, setDecision] = React.useState<Recurring>('all')
  const formattedDate = selectedDateOnly.formatNYearNMonthNDayFWeek()

  const choices = React.useMemo(() => {
    const isEdit = mode === 'edit'
    const res: RadioChoice<Recurring>[] = [
      {
        value: 'all',
        label: formatMessage(accessRulesMessages.decisionModeAllLabel, { isEdit }),
        description: formatMessage(accessRulesMessages.decisionModeAllDescription, { isEdit, name }),
      },
    ]

    if (!props.isSelectedDayToday) {
      res.push({
        value: 'following',
        label: formatMessage(accessRulesMessages.decisionModeFollowingLabel, { isEdit, date: formattedDate }),
        description: formatMessage(accessRulesMessages.decisionModeFollowingDescription, {
          name,
          isEdit,
          date: formattedDate,
        }),
      })
    }

    res.push({
      value: 'override',
      label: formatMessage(accessRulesMessages.decisionModeOverrideLabel, {
        date: formattedDate,
        isEdit,
      }),
      description: formatMessage(accessRulesMessages.decisionModeOverrideDescription, { name }),
    })

    return res
  }, [name, props.isSelectedDayToday, formattedDate, formatMessage, mode])

  return (
    <Window active={props.active}>
      <Modal ariaLabel="main" data-test={RecurringDecisionTestId.modal}>
        <ModalHeader
          onClose={e => {
            e.preventDefault()
            props.setIsActive(false)
          }}
        >
          <ModalTitle title={formatMessage(accessRulesMessages.decisionModeTitle, { isEdit: mode === 'edit', name })} />
        </ModalHeader>

        <ModalBody>
          <RadioGroup
            data-test={RecurringDecisionTestId.choices}
            name="recurring-decision"
            selected={decision}
            onChange={v => setDecision(v.value)}
            choices={choices}
          />
        </ModalBody>

        <ModalFooter>
          <ModalActions>
            <Button
              data-test={RecurringDecisionTestId.cancel}
              variant="secondary"
              onClick={e => {
                e.preventDefault()
                props.setIsActive(false)
              }}
            >
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button
              data-test={RecurringDecisionTestId.continue}
              variant="primary"
              onClick={e => {
                e.preventDefault()
                props.onSuccess(decision)
              }}
            >
              {formatMessage(commonMessages.continue)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
