import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { getAccessRuleDefaults } from '../../../AccessRule.zod'
import { AccessRuleTooltip, useAccessRuleContext, useIsDefault } from '../../shared'
import { GuestFacingLocales } from '../GuestFacing.locales'
import { GuestFacingTestId } from '../GuestFacing.testIds'
import type { GuestFacingProps } from './GuestFacingProps'

export function NotBookableTooltip({ field }: Omit<GuestFacingProps, 'paymentPolicy'>) {
  const { formatMessage } = useLocales()
  const context = useAccessRuleContext()
  const allowUnsupported = useWatch(field.prop('allowUnsupported'))
  const AccessRuleDefaults = getAccessRuleDefaults(context)
  const { isDefault } = useIsDefault(field, AccessRuleDefaults.guestFacing)

  // requires Booking Channels Audience section
  const hasUnsupportedAudiences = false

  const showTooltip = !allowUnsupported && hasUnsupportedAudiences && !isDefault

  return showTooltip ? (
    <>
      <Space />
      <AccessRuleTooltip data-test={GuestFacingTestId.notBookableTooltip} variant="warning">
        <Text color="lightFont">{formatMessage(GuestFacingLocales.notBookableTooltip)}</Text>
        <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/360017704331">
          {formatMessage(GuestFacingLocales.notBookableLink)}
        </Anchor>
      </AccessRuleTooltip>
    </>
  ) : null
}

function Space() {
  return <Text> </Text>
}
