import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { CollapsedGuestFacing, DefaultGuestFacingSettings, GuestFacingFields, type GuestFacingProps } from './components'
import { GuestFacingLocales } from './GuestFacing.locales'
import { GuestFacingTestId } from './GuestFacing.testIds'

export function GuestFacing(props: GuestFacingProps) {
  const { formatMessage } = useLocales()

  return (
    <AccessRuleSlideoutSection
      data-test={GuestFacingTestId.section}
      title={formatMessage(GuestFacingLocales.title)}
      description={formatMessage(GuestFacingLocales.description)}
      subCaption={formatMessage(GuestFacingLocales.subCaption)}
      contentWhenCollapsed={<CollapsedGuestFacing {...props} />}
      defaultSettings={<DefaultGuestFacingSettings />}
      sectionControllerName="guestFacing"
      field={props.field}
    >
      <GuestFacingFields {...props} />
    </AccessRuleSlideoutSection>
  )
}
