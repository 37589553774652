import { defineMessages } from '@sevenrooms/core/locales'

export const PaymentPolicyLocales = defineMessages({
  title: {
    id: 'ar.paymentPolicy.title',
    defaultMessage: 'Payment and Policy',
  },
  description: {
    id: 'ar.paymentPolicy.description',
    defaultMessage: 'Ex: Collect a deposit for reservations with more than 6 guests.',
  },
  subCaption: {
    id: 'ar.paymentPolicy.subCaption',
    defaultMessage: 'Require a deposit, prepayment or enforce a cancellation policy.',
  },
  // Headings
  requirePayment: {
    id: 'ar.paymentPolicy.requirePayment',
    defaultMessage: 'Require Payment',
  },
  requirePaymentCollapsed: {
    id: 'ar.paymentPolicy.requirePaymentCollapsed',
    defaultMessage: 'Require Payment:',
  },
  bundledUpgrades: {
    id: 'ar.paymentPolicy.bundledUpgrades',
    defaultMessage: 'Bundled Upgrades',
  },
  bundledUpgradesCollapsed: {
    id: 'ar.paymentPolicy.bundledUpgradesCollapsed',
    defaultMessage: 'Bundled Upgrades:',
  },
  bundledUpgradesDescription: {
    id: 'ar.paymentPolicy.bundledUpgradesDescription',
    defaultMessage: 'Bundled Upgrades will be automatically included with any reservation made through this Access Rule.',
  },
  automaticRefund: {
    id: 'ar.paymentPolicy.automaticRefund',
    defaultMessage: 'Automatic Refund',
  },
  automaticRefundCollapsed: {
    id: 'ar.paymentPolicy.automaticRefundCollapsed',
    defaultMessage: 'Automatic Refund:',
  },
  cancelCharge: {
    id: 'ar.paymentPolicy.cancelCharge',
    defaultMessage: 'Automatic cancellation / No-show charge',
  },
  cancelChargeCollapsed: {
    id: 'ar.paymentPolicy.cancelChargeCollapsed',
    defaultMessage: 'Automatic cancellation / No-show charge:',
  },
  customerCanCancel: {
    id: 'ar.paymentPolicy.customerCanCancel',
    defaultMessage: 'Customer can cancel',
  },
  customerCanCancelCollapsed: {
    id: 'ar.paymentPolicy.customerCanCancelCollapsed',
    defaultMessage: 'Customer can cancel:',
  },
  customerCanCancelLonger: {
    id: 'ar.paymentPolicy.customerCanCancelLonger',
    defaultMessage: 'Customer can modify/cancel online',
  },
  customerCanCancelDescription: {
    id: 'ar.paymentPolicy.customerCanCancelDescription',
    defaultMessage: 'via the links in their Booking Notification Emails',
  },
  bookingPolicy: {
    id: 'ar.paymentPolicy.bookingPolicy',
    defaultMessage: 'Booking Policy',
  },
  bookingPolicyCollapsed: {
    id: 'ar.paymentPolicy.bookingPolicyCollapsed',
    defaultMessage: 'Booking Policy:',
  },
  cancellationPolicy: {
    id: 'ar.paymentPolicy.cancellationPolicy',
    defaultMessage: 'Cancellation Policy',
  },
  cancellationPolicyCollapsed: {
    id: 'ar.paymentPolicy.cancellationPolicyCollapsed',
    defaultMessage: 'Cancellation Policy:',
  },
  // Input
  defaultBookingPolicy: {
    id: 'ar.paymentPolicy.defaultBookingPolicy',
    defaultMessage: 'Default Booking Policy',
  },
  defaultCancellationPolicy: {
    id: 'ar.paymentPolicy.defaultCancellationPolicy',
    defaultMessage: 'Default Cancellation Policy',
  },
  customPolicy: {
    id: 'ar.paymentPolicy.customPolicy',
    defaultMessage: 'Custom Policy',
  },
  saveCC: {
    id: 'ar.paymentPolicy.saveCC',
    defaultMessage: 'Save credit card details for later',
  },
  paymentChoiceNone: {
    id: 'ar.paymentPolicy.paymentChoiceNone',
    defaultMessage: 'No payment required at time of booking',
  },
  paymentChoiceLater: {
    id: 'ar.paymentPolicy.paymentChoiceLater',
    defaultMessage: 'Require credit card with reservation booking (Credit Card Guarantee)',
  },
  paymentChoiceLaterDescription: {
    id: 'ar.paymentPolicy.paymentChoiceLaterDescription',
    defaultMessage: "You'll be able to charge the card up to 7 days after the reservation date",
  },
  paymentChoiceImmediately: {
    id: 'ar.paymentPolicy.paymentChoiceImmediately',
    defaultMessage: 'Charge credit card at time of booking',
  },
  refundChoiceNone: {
    id: 'ar.paymentPolicy.refundChoiceNone',
    defaultMessage: 'No refund',
  },
  refundChoiceFull: {
    id: 'ar.paymentPolicy.refundChoiceFull',
    defaultMessage: 'Full refund',
  },
  refundChoicePartial: {
    id: 'ar.paymentPolicy.refundChoicePartial',
    defaultMessage: 'Partial refund',
  },
  penaltyChoiceNone: {
    id: 'ar.paymentPolicy.penaltyChoiceNone',
    defaultMessage: 'No charge',
  },
  penaltyChoiceCharge: {
    id: 'ar.paymentPolicy.penaltyChoiceCharge',
    defaultMessage: 'Automatic charge',
  },
  cancelChoiceAnyTime: {
    id: 'ar.paymentPolicy.cancelChoiceAnyTime',
    defaultMessage: 'At any time',
  },
  cancelChoiceNever: {
    id: 'ar.paymentPolicy.cancelChoiceNever',
    defaultMessage: 'Never',
  },
  cancelChoiceUntilCutoff: {
    id: 'ar.paymentPolicy.cancelChoiceUntilCutoff',
    defaultMessage: 'Up until a cut-off time',
  },
  partySizeChoiceGt: {
    id: 'ar.paymentPolicy.partySizeChoiceGt',
    defaultMessage: 'Party sizes of more than',
  },
  partySizeChoiceAll: {
    id: 'ar.paymentPolicy.partySizeChoiceAll',
    defaultMessage: 'All party sizes',
  },
  payPerChoicePerson: {
    id: 'ar.paymentPolicy.payPerChoicePerson',
    defaultMessage: 'per person',
  },
  payPerChoicePersonSlot: {
    id: 'ar.paymentPolicy.payPerChoicePersonSlot',
    defaultMessage: 'per person per 15 minutes',
  },
  payPerChoiceReservation: {
    id: 'ar.paymentPolicy.payPerChoiceReservation',
    defaultMessage: 'per reservation',
  },
  payPerChoiceReservationSlot: {
    id: 'ar.paymentPolicy.payPerChoiceReservationSlot',
    defaultMessage: 'per reservation per 15 minutes',
  },
  applyCharge: {
    id: 'ar.paymentPolicy.applyCharge',
    defaultMessage: 'Apply Service Charge (taxed)',
  },
  chargeChoiceDefault: {
    id: 'ar.paymentPolicy.chargeChoiceDefault',
    defaultMessage: 'Default Service charge ({charge, number, percent})',
  },
  chargeChoiceSpecific: {
    id: 'ar.paymentPolicy.chargeChoiceSpecific',
    defaultMessage: 'Specific service charge',
  },
  applyTax: {
    id: 'ar.paymentPolicy.applyTax',
    defaultMessage: 'Apply Tax',
  },
  taxDescription: {
    id: 'ar.paymentPolicy.taxDescription',
    defaultMessage: 'To configure tax rates, visit the <a>Taxes Rates</a> settings page',
  },
  applyGratuity: {
    id: 'ar.paymentPolicy.applyGratuity',
    defaultMessage: 'Apply Gratuity (not taxed)',
  },
  gratuityChoiceDefault: {
    id: 'ar.paymentPolicy.gratuityChoiceDefault',
    defaultMessage: 'Default gratuity ({charge, number, percent})',
  },
  gratuityChoiceSpecific: {
    id: 'ar.paymentPolicy.gratuityChoiceSpecific',
    defaultMessage: 'Specific gratuity',
  },
  gratuityChoiceClient: {
    id: 'ar.paymentPolicy.gratuityChoiceClient',
    defaultMessage: 'Allow client to select gratuity',
  },
  requireClientGratuity: {
    id: 'ar.paymentPolicy.requireClientGratuity',
    defaultMessage: 'Require client to select gratuity',
  },
  searchUpgrades: {
    id: 'ar.paymentPolicy.searchUpgrades',
    defaultMessage: 'Search upgrade(s)',
  },
  quantity: {
    id: 'ar.paymentPolicy.quantity',
    defaultMessage: 'Quantity',
  },
  quantityChoiceParty: {
    id: 'ar.paymentPolicy.quantityChoiceParty',
    defaultMessage: 'Equal to party size',
  },
  quantityChoiceFixed: {
    id: 'ar.paymentPolicy.quantityChoiceFixed',
    defaultMessage: 'Fixed number per reservation',
  },
  addAnUpgrade: {
    id: 'ar.paymentPolicy.addAnUpgrade',
    defaultMessage: 'Add an upgrade',
  },
  setRefundCutoff: {
    id: 'ar.paymentPolicy.setRefundCutoff',
    defaultMessage: 'Set a refund cutoff time',
  },
  setRefundCutoffDescription: {
    id: 'ar.paymentPolicy.setRefundCutoffDescription',
    defaultMessage: 'Automatic refund will be triggered if cancelled before this time',
  },
  setChargeCutoff: {
    id: 'ar.paymentPolicy.setChargeCutoff',
    defaultMessage: 'Set a charge cutoff time',
  },
  setChargeCutoffDescription: {
    id: 'ar.paymentPolicy.setChargeCutoffDescription',
    defaultMessage: 'Automatic charge will only be triggered after this time',
  },
  // Errors
  partySizeRequired: {
    id: 'ar.paymentPolicy.partySizeRequired',
    defaultMessage: 'Party size is required',
  },
  chargeRequired: {
    id: 'ar.paymentPolicy.chargeRequired',
    defaultMessage: 'Credit card charge price required',
  },
  chargeGt0: {
    id: 'ar.paymentPolicy.chargeGt0',
    defaultMessage: 'Enter credit card charge price over $0',
  },
  serviceChargeRequired: {
    id: 'ar.paymentPolicy.serviceChargeRequired',
    defaultMessage: 'Enter a service charge over 0%',
  },
  gratuityRequired: {
    id: 'ar.paymentPolicy.gratuityRequired',
    defaultMessage: 'Enter a gratuity over 0%',
  },
  refundRequired: {
    id: 'ar.paymentPolicy.refundRequired',
    defaultMessage: 'Percentage of Advanced Payment required',
  },
  refundGte1: {
    id: 'ar.paymentPolicy.refundGte1',
    defaultMessage: 'Enter percentage of at least 1%',
  },
  cutoffRequired: {
    id: 'ar.paymentPolicy.cutoffRequired',
    defaultMessage: 'Cut-off time required',
  },
  // depends on audiences
  unsupportedInfo: {
    id: 'ar.paymentPolicy.unsupportedInfo',
    defaultMessage:
      'Please note: Channel1 and Channel2 do not support credit card holds. Guests will be able to book this Access Rule <strong>without a credit card</strong> on those channels.',
  },
  unsupportedWarning: {
    id: 'ar.paymentPolicy.unsupportedWarning',
    defaultMessage:
      'Please note: Channel1 and Channel2 do not support credit card holds. Guests will <strong>not be able to book</strong> this Access Rule on those channels.',
  },
  // Misc
  followShiftSettingsLabel: {
    id: 'ar.paymentPolicy.followShiftSettingsLabel',
    defaultMessage: 'Follow <a>Shift Settings</a> for Payment and Policy',
  },
  defaultSettingsModalTitle: {
    id: 'ar.paymentPolicy.defaultSettingsModalTitle',
    defaultMessage: 'Shift Settings - Payment and Policy',
  },
  defaultSettingsModalSubtext: {
    id: 'ar.paymentPolicy.defaultSettingsModalSubtext',
    defaultMessage:
      'Below are the settings for the Shifts that this Access Rule is applied to. If you chose <strong>not to follow</strong> Shift Settings, these will be overriden by the Access Rule settings.',
  },
  bookOnUnsupportedLabel: {
    id: 'ar.paymentPolicy.bookOnUnsupportedLabel',
    defaultMessage: 'Allow this access rule to be booked on channels that do not support credit card holds',
  },
  doNotBookOnUnsupported: {
    id: 'ar.paymentPolicy.doNotBookOnUnsupported',
    defaultMessage: '<strong>Do not</strong> allow this access rule to be booked on channels that do not support credit card holds',
  },
  refundTooltipHeading: {
    id: 'ar.paymentPolicy.refundTooltipHeading',
    defaultMessage: 'Why am I seeing this if credit card is not required to book?',
  },
  refundTooltipContent: {
    id: 'ar.paymentPolicy.refundTooltipContent',
    defaultMessage:
      'Even if credit card is not required for booking a reservation, Automatic Refund settings can apply to optional paid items such as Selectable Upgrades and manual pre-payments.',
  },
  for: {
    id: 'ar.paymentPolicy.for',
    defaultMessage: 'for',
  },
  guests: {
    id: 'ar.paymentPolicy.guests',
    defaultMessage: 'guests',
  },
  setting: {
    id: 'ar.paymentPolicy.setting',
    defaultMessage: 'Setting',
  },
  total: {
    id: 'ar.paymentPolicy.total',
    defaultMessage: '<strong>Total charged at time of booking</strong> (excluding service charge, tax, and gratuity):',
  },
  setupPayments: {
    id: 'ar.paymentPolicy.setupPayments',
    defaultMessage: 'Connect a processor in minutes to enable online payments.',
  },
  upUntilGivenCutoff: {
    id: 'ar.paymentPolicy.upUntilGivenCutoff',
    defaultMessage: `{time, select,
      0 {
        {unit, select,
          MINUTES {
            {num, plural,
              one {Up until one minute before reservation}
              other {Up until # minutes before reservation}
            }
          }
          DAYS {
            {num, plural,
              one {Up until one day before reservation}
              other {Up until # days before reservation}
            }
          }
          WEEKS {
            {num, plural,
              one {Up until one week before reservation}
              other {Up until # weeks before reservation}
            }
          }
          MONTHS {
            {num, plural,
              one {Up until one month before reservation}
              other {Up until # months before reservation}
            }
          }
          other {
            {num, plural,
              one {Up until one hour before reservation}
              other {Up until # hours before reservation}
            }
          }
        }
      }
      other {
        {unit, select,
          MINUTES {
            {num, plural,
              one {Up until one minute before {time}}
              other {Up until # minutes before {time}}
            }
          }
          DAYS {
            {num, plural,
              one {Up until one day before {time}}
              other {Up until # days before {time}}
            }
          }
          WEEKS {
            {num, plural,
              one {Up until one week before {time}}
              other {Up until # weeks before {time}}
            }
          }
          MONTHS {
            {num, plural,
              one {Up until one month before {time}}
              other {Up until # months before {time}}
            }
          }
          other {
            {num, plural,
              one {Up until one hour before {time}}
              other {Up until # hours before {time}}
            }
          }
        }
      }
    }`,
  },
} as const)
