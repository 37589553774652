import _ from 'lodash'
import { useMemo } from 'react'
import {
  type AccessRule,
  type AccessRuleAudienceTier,
  AccessRuleTagRestrictionEnum,
  type AccessRuleTagRestrictionType,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import {
  useMultiSelectTreeForm,
  useMultiSelectTagOptionForm,
  type Category,
  type MultiSelectTagOptionsForm,
} from '@sevenrooms/core/ui-kit/form'
import { notNullish } from '@sevenrooms/core/utils'
import { timeBeforeForm } from '../shared/timeBeforeForm'
import { generateTimeSlots } from '../shared/utils'
import { BookingChannelsLocales } from './BookingChannels.locales'
import type { AudienceHierarchy, TagGroup } from '../../AccessRule.types'

export type BookingChannelsForm = ZodSchema<typeof useBookingChannelsForm>

export function useBookingChannelsForm() {
  const { formatMessage } = useLocales()
  const selected = useMultiSelectTreeForm()
  const bookClientTagsOptions = useMultiSelectTagOptionForm()
  const viewClientTagsOptions = useMultiSelectTagOptionForm()

  return useMemo(
    () =>
      z.array(
        z
          .object({
            audienceTierId: z.string().nullable(),
            selected,
            startTime: timeBeforeForm,
            tagRestriction: z.enum(Object.keys(AccessRuleTagRestrictionEnum) as [AccessRuleTagRestrictionType]),
            bookClientTags: z.object({ tags: bookClientTagsOptions, hasDeletedTags: z.boolean() }),
            viewClientTags: z.object({ tags: viewClientTagsOptions, hasDeletedTags: z.boolean() }),
          })
          .superRefine(({ startTime, selected, tagRestriction, bookClientTags, viewClientTags }, ctx) => {
            if (!startTime.count) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingChannelsLocales.startTimeRequired),
                path: ['startTime.count'],
              })
            }
            if (!selected || !selected.length) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingChannelsLocales.audienceRequired),
                path: ['selected'],
              })
            }
            if (tagRestriction === AccessRuleTagRestrictionEnum.BOOK && !bookClientTags.tags.length) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingChannelsLocales.guestTagsRequired),
                path: ['bookClientTags.tags'],
              })
            }
            if (tagRestriction === AccessRuleTagRestrictionEnum.VIEW && !viewClientTags.tags.length) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingChannelsLocales.guestTagsRequired),
                path: ['viewClientTags.tags'],
              })
            }
          })
      ),
    [selected, bookClientTagsOptions, viewClientTagsOptions, formatMessage]
  )
}

export const flattenAudience = (items?: AudienceHierarchy[] | null): AudienceHierarchy[] =>
  !items ? [] : _.flattenDeep(items.map(item => [item, flattenAudience(item.children)]))

export function getInitialBookingChannels(arState: {
  accessRule?: AccessRule
  audienceHierarchy?: AudienceHierarchy[]
  defaultBookingChannels: BookingChannelsForm
  startOfDayTime: string
  clientTagGroups: Map<string, TagGroup>
}): BookingChannelsForm {
  const { accessRule, audienceHierarchy, defaultBookingChannels, startOfDayTime, clientTagGroups } = arState
  const audienceOptions = flattenAudience(audienceHierarchy)
  const timeSlots = generateTimeSlots(startOfDayTime)

  if (!accessRule?.audienceTiers) {
    return defaultBookingChannels
  }
  return accessRule.audienceTiers.map(tier => {
    const selected = [...tier.channels, ...tier.concierges, ...tier.thirdParties]
    const clientTags = getInitialClientTags({ tier, clientTagGroups })
    const hasDeletedTags = clientTags.includes(null)
    const filteredClientTags = clientTags.filter(notNullish)

    return {
      audienceTierId: tier.audienceTierId ?? null,
      selected: audienceOptions
        .filter(option => selected.includes(option.value))
        .map(({ name, value }) => ({
          id: value,
          value,
          label: name,
          checked: true,
        })),
      startTime: {
        count: tier.startNum ?? null,
        unit: tier.startType,
        beforeTime: tier.startHourDisplay ? timeSlots.find(item => item.id === tier.startHour)?.id ?? '0' : '0',
        isEarlyAccess: tier.isEarlyAccess,
      },
      tagRestriction: tier.tagRestriction ?? AccessRuleTagRestrictionEnum.NONE,
      viewClientTags: {
        tags: tier.tagRestriction === AccessRuleTagRestrictionEnum.VIEW ? filteredClientTags : [],
        hasDeletedTags,
      },
      bookClientTags: {
        tags: tier.tagRestriction === AccessRuleTagRestrictionEnum.BOOK ? filteredClientTags : [],
        hasDeletedTags,
      },
    }
  })
}

function getInitialClientTags(initParams: {
  tier: AccessRuleAudienceTier
  clientTagGroups: Map<string, TagGroup>
}): (MultiSelectTagOptionsForm[number] | null)[] {
  const { tier, clientTagGroups } = initParams
  const availableTags: string[] = []
  clientTagGroups.forEach(tagGroup =>
    tagGroup.tags.forEach(tag => availableTags.push(generateTagHash(tagGroup.privacy, tagGroup.id, tagGroup.name, tag)))
  )
  return parseTagHash(
    tier.clientTags
      ? tier.clientTags.map(tag => {
          const isValid = availableTags.includes(tag)
          return isValid ? tag : null
        })
      : [],
    clientTagGroups
  )
}

function parseTagHash(clientTags: (string | null)[], clientTagGroups: Map<string, TagGroup>) {
  const selectedTags: ({ id: string; categoryId: string; label: string } | null)[] = []
  for (const tagHash of clientTags) {
    if (!tagHash) {
      selectedTags.push(null)
      continue
    }

    const tagParts = tagHash.split('##')
    const tagGroupId = tagParts[1]
    const tagName = tagParts[3]
    if (tagGroupId && tagName) {
      selectedTags.push({
        id: tagHash,
        categoryId: tagGroupId,
        label: clientTagGroups.get(tagGroupId)?.tagNameDisplays[tagName] ?? tagName,
      })
    }
  }
  return selectedTags
}

export function generateTagHash(privacy: string, groupId: string, groupName: string, tagName: string) {
  return [privacy, groupId, groupName, tagName].join('##')
}

export function getFormattedCategories(tagGroups: Map<string, TagGroup>): Category[] {
  const categories: Category[] = []
  tagGroups.forEach(tagGroup =>
    categories.push({
      id: tagGroup.id,
      name: tagGroup.nameDisplay || tagGroup.name,
      color: tagGroup.colorHex,
    })
  )
  return categories
}
