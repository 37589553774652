import { useMemo } from 'react'
import type { AccessRule } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'

export type GuestFacingForm = ZodSchema<typeof useGuestFacingForm>

export function useGuestFacingForm() {
  const image = useImageForm()

  return useMemo(
    () =>
      z.object({
        timeslotDescription: z.string(),
        title: z.string(),
        description: z.string(),
        image: z.nullable(image),
        offer: z.string().nullable(),
        allowUnsupported: z.boolean(),
      }),
    [image]
  )
}

export function getInitialGuestFacing(arState: { accessRule?: AccessRule; editPhoto: string }): GuestFacingForm {
  const { accessRule, editPhoto } = arState
  const description = accessRule?.publicLongFormDescription ?? ''
  return {
    timeslotDescription: accessRule?.publicTimeSlotDescription ?? '',
    title: accessRule?.publicDescriptionTitle ?? '',
    description: description === '<p><br></p>' ? '' : description,
    image: accessRule?.publicPhoto
      ? {
          name: editPhoto,
          rawUrl: accessRule?.publicPhoto,
        }
      : null,
    offer: accessRule?.experienceID ? accessRule?.experienceID : null,
    allowUnsupported: accessRule?.ignoreDescriptionsFor3PBookers ?? false,
  }
}
