import { useLocales } from '@sevenrooms/core/locales'
import { BasicButton } from '@sevenrooms/core/ui-kit/form'
import { Icon, type IconProps } from '@sevenrooms/core/ui-kit/icons'
import { Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../AccessRules.locales'
import type { PropsWithChildren, ReactNode } from 'react'

export interface AccessRuleTooltipProps {
  title?: ReactNode
  variant?: 'info' | 'warning'
  size?: IconProps['size']
  'data-test'?: string
}
export function AccessRuleTooltip({ 'data-test': testId, ...props }: PropsWithChildren<AccessRuleTooltipProps>) {
  const { formatMessage } = useLocales()
  return (
    <Tooltip data-test={testId} title={props.title} content={<VStack spacing="sm">{props.children}</VStack>}>
      <BasicButton aria-label={formatMessage(accessRulesMessages.ariaTooltip)}>
        <Icon name={`VMSWeb-${props.variant ?? 'info'}`} color={props.variant ?? 'primaryIcons'} size={props.size ?? 'lg'} />
      </BasicButton>
    </Tooltip>
  )
}
