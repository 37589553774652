import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '@sevenrooms/mgr-access-rules-slideout/AccessRules.locales'
import { ConfirmationDialogTestId } from './ConfirmationDialog.testIds'

export type Recurring = 'override' | 'following' | 'all'

export interface ConfirmationDialogProps {
  active: boolean
  name?: string
  selectedDateOnly: DateOnly
  decision: Recurring
  setIsActive: (value: boolean) => void
  onSuccess: () => void
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { formatMessage } = useLocales()
  const { selectedDateOnly, name = '', decision } = props
  const formattedDate = selectedDateOnly.formatNYearNMonthNDayFWeek()

  return (
    <Window active={props.active}>
      <Modal ariaLabel="main" data-test={ConfirmationDialogTestId.modal}>
        <ModalHeader
          onClose={e => {
            e.preventDefault()
            props.setIsActive(false)
          }}
        >
          <ModalTitle title={formatMessage(accessRulesMessages.deleteConfirmationTitle, { name, decision, formattedDate })} />
        </ModalHeader>

        <ModalBody>
          <Text>{formatMessage(accessRulesMessages.deleteConfirmationBody)}</Text>
        </ModalBody>

        <ModalFooter>
          <ModalActions>
            <Button
              data-test={ConfirmationDialogTestId.cancel}
              variant="secondary"
              onClick={e => {
                e.preventDefault()
                props.setIsActive(false)
              }}
            >
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button
              data-test={ConfirmationDialogTestId.confirm}
              variant="primary-warning"
              onClick={e => {
                e.preventDefault()
                props.onSuccess()
              }}
            >
              {formatMessage(commonMessages.delete)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
