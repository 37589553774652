import { useMemo } from 'react'
import type { TaxRate } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { FormSelect, Checkbox, FormPercentageInput, type RadioChoice, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { StandardWidthBox, CheckboxAlignedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { ChargeSettingsLocales } from './ChargeSettings.locales'
import { DisplayIfChecked, DisplayIfSelected } from './ConditionalDisplay'
import type { ChargeSettingsForm } from './chargeSettingsForm'

export interface ChargeSettingsProps {
  field: Field<ChargeSettingsForm>
  taxRates: TaxRate[]
  'data-test': string
}

export function ChargeSettings({ 'data-test': testId, field, taxRates }: ChargeSettingsProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueId: venueKey } = useAppContext()

  const applyTax = formatMessage(ChargeSettingsLocales.applyTax)
  const taxDescription = formatMessage(ChargeSettingsLocales.taxDescription, {
    a: (chunks: string[]) => <Anchor href={nav.href(routes.manager.manage.taxRates, { params: { venueKey } })}>{chunks}</Anchor>,
  })
  return (
    <VStack spacing="s" data-test={testId}>
      <Checkbox data-test="apply-service-charge" field={field.prop('applyServiceCharge')}>
        {formatMessage(ChargeSettingsLocales.applyCharge)}
      </Checkbox>
      <DisplayIfChecked field={field.prop('applyServiceCharge')}>
        <CheckboxAlignedBox>
          <ServiceChargeRadios field={field} />
        </CheckboxAlignedBox>
      </DisplayIfChecked>
      {taxRates.length > 0 ? (
        <Checkbox data-test="apply-tax" field={field.prop('applyTax')} description={taxDescription}>
          {applyTax}
        </Checkbox>
      ) : (
        <Checkbox data-test="apply-tax" disabled description={taxDescription}>
          {applyTax}
        </Checkbox>
      )}
      <DisplayIfChecked field={field.prop('applyTax')}>
        <CheckboxAlignedBox>
          <TaxRateSelect field={field} taxRates={taxRates} />
        </CheckboxAlignedBox>
      </DisplayIfChecked>
      <Checkbox data-test="apply-gratuity" field={field.prop('applyGratuity')}>
        {formatMessage(ChargeSettingsLocales.applyGratuity)}
      </Checkbox>
      <DisplayIfChecked field={field.prop('applyGratuity')}>
        <CheckboxAlignedBox>
          <GratuityRadios field={field} />
        </CheckboxAlignedBox>
      </DisplayIfChecked>
    </VStack>
  )
}

interface ChargeSettingsRadioProps {
  field: Field<ChargeSettingsForm>
}

function ServiceChargeRadios({ field }: ChargeSettingsRadioProps) {
  const { formatMessage } = useLocales()
  const { defaultServiceCharge } = useAppContext().venueSettings
  const choices: RadioChoice<ChargeSettingsForm['serviceChargeType']>[] = useMemo(
    () => [
      {
        value: 'DEFAULT_SERVICE_CHARGE',
        label: formatMessage(ChargeSettingsLocales.chargeChoiceDefault, { charge: defaultServiceCharge / 100 }),
      },
      {
        value: 'SPECIFIC_SERVICE_CHARGE',
        label: formatMessage(ChargeSettingsLocales.chargeChoiceSpecific),
        description: (
          <DisplayIfSelected field={field.prop('serviceChargeType')} option="SPECIFIC_SERVICE_CHARGE">
            <StandardWidthBox>
              <FormPercentageInput data-test="service-charge-percent" field={field.prop('serviceChargePercent')} />
            </StandardWidthBox>
          </DisplayIfSelected>
        ),
      },
    ],
    [defaultServiceCharge, formatMessage, field]
  )
  return <RadioGroup data-test="service-charge-radios" choices={choices} field={field.prop('serviceChargeType')} />
}

interface TaxRateSelectProps {
  field: Field<ChargeSettingsForm>
  taxRates: TaxRate[]
}
function TaxRateSelect({ field, taxRates }: TaxRateSelectProps) {
  const options = useMemo(
    () =>
      taxRates
        .sort((a, b) => a.created.localeCompare(b.created))
        .map(tax => {
          const formattedPercent = Intl.NumberFormat(TimeLocale.getLocale(), { style: 'percent', maximumFractionDigits: 10 }).format(
            tax.rate / 100
          )
          return {
            id: tax.id,
            label: `${formattedPercent} ${tax.name}`,
          }
        }),
    [taxRates]
  )
  return (
    <StandardWidthBox>
      <FormSelect data-test="tax-select" field={field.prop('taxId')} withEmpty hideEmptyOption options={options} searchable={false} />
    </StandardWidthBox>
  )
}

function GratuityRadios({ field }: ChargeSettingsRadioProps) {
  const { formatMessage } = useLocales()
  const { defaultGratuity } = useAppContext().venueSettings
  const choices: RadioChoice<ChargeSettingsForm['gratuityType']>[] = useMemo(
    () => [
      {
        value: 'DEFAULT_GRATUITY',
        label: formatMessage(ChargeSettingsLocales.gratuityChoiceDefault, { charge: defaultGratuity / 100 }),
      },
      {
        value: 'SPECIFIC_GRATUITY',
        label: formatMessage(ChargeSettingsLocales.gratuityChoiceSpecific),
        description: (
          <DisplayIfSelected field={field.prop('gratuityType')} option="SPECIFIC_GRATUITY">
            <StandardWidthBox>
              <FormPercentageInput data-test="gratuity-percent" field={field.prop('gratuityPercent')} />
            </StandardWidthBox>
          </DisplayIfSelected>
        ),
      },
      {
        value: 'CLIENT_GRATUITY',
        label: formatMessage(ChargeSettingsLocales.gratuityChoiceClient),
        description: (
          <DisplayIfSelected field={field.prop('gratuityType')} option="CLIENT_GRATUITY">
            <Checkbox data-test="require-gratuity" field={field.prop('requireGratuity')}>
              {formatMessage(ChargeSettingsLocales.requireClientGratuity)}
            </Checkbox>
          </DisplayIfSelected>
        ),
      },
    ],
    [defaultGratuity, formatMessage, field]
  )
  return <RadioGroup data-test="gratuity-radios" choices={choices} field={field.prop('gratuityType')} />
}
